.default-theme {
  & .Nav-exit-btn {
    text-transform: uppercase;
  }

  & .MuiLink-root {
    color: var(--color-blue);
    cursor: pointer;
  }

  & .MuiLink-underlineHover:hover {
    color: var(--color-medium-blue);
    text-decoration: none;
  }

}
