// HTML elements

html, 
body {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

body {
  line-height: 1.4em;
  //min-width: 230px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

a {
	color: var(--color-blue);
	text-decoration: none;
	transition: all 250ms;

	&:hover {
  	color: var(--color-medium-blue);
	}

}

h1,
h2,
h3,
h4 {
	font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

h1,
h2,
h3 {
	font-weight: 500;
}

h1 {
	font-size: 50px;
	//font-weight: 900;
}

h2 {
	font-size: 40px;
}

h3,
h3[class*="MuiTypography-"] {
/*
	font-size: 24px;
	font-weight: 700;
*/
}

h4 {
	font-size: 24px;
  font-weight: normal;
}

td {
	padding: 12px;
}

code {
  display: block;
  background-color: #dce1e8;
  border-radius: rem(9);
  padding: rem(15);
  font-size: 0.75rem;
  color: #47505d;
}
