// Global color variables

$vin-black: #000;
$vin-white: #000;

$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;


// usage from https://codyhouse.co/blog/post/css-custom-properties-vs-sass-variables

:root, [data-theme="default"] {
  --color-primary: 					#04593D; //green
  --color-secondary: 				hsl(201, 100%, 56%); //blue

  --color-white: 						#fff;

  // brand greens
  --color-dark: 						hsl(161, 97%, 14%);
  --color-medium: 					hsl(160, 100%, 28%);
  --color-regular: 					hsl(160, 85%, 40%);
  --color-light: 						hsl(160, 60%, 83%);

  /* color contrasts */
  --color-bg: 							white;
  --color-contrast-lowest: 	hsl(240, 25%, 98%);
  --color-contrast-low: 		hsl(220, 13%, 91%);
  --color-contrast-medium: 	hsl(210, 6%, 63%);
  --color-contrast-high: 		hsl(236, 7%, 48%);
  --color-contrast-highest:	hsl(215, 13%, 32%);

  // addl colors
  --color-grey: rgb(114, 115, 131);
  --color-medium-grey: rgb(154, 160, 166);
  --color-light-grey: rgba(229, 231, 235, 1);

  --color-blue: rgb(29, 175, 255);
  --color-pale-blue: rgb(195, 231, 250);
  --color-medium-blue: #0d94df;

  --color-green: rgb(15, 191, 132);
  --color-pale-green: rgba(122, 220, 187, 0.6);

}

[data-theme] {
  background-color: var(--color-bg);
  color: var(--color-contrast-high);
}

[data-theme="dark"] {
  --color-primary: red;
  /* color contrasts */
  --color-bg: black;
  --color-contrast-lowest: hsl(240, 6%, 15%);
  --color-contrast-low: hsl(252, 4%, 25%);
  --color-contrast-medium: hsl(240, 1%, 57%);
  --color-contrast-high: hsl(0, 0%, 89%);
  --color-contrast-highest: white;
}
