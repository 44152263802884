// STRUCTURE
// Styles pulled from subfolders
// base: for globals like variables, mixins, and HTML elemet styles
// components: typically matches with componenets in the app. can include overrides or updates to MUI components
// views: for whole page or sectio wrappers

// NAMING
// Styles use the BEM (Block-element--modifier) method
// e.g. class="Button-submit--large"
// Status modifiers can be set separatly in combo
// e.g. class="Nav-item active"

// VIEWS
// the CSS in the views folder has section or page specific styling
// these still use BEM structure but have a lot more fudgability since
// these are usually exceptions to the established global style framework

// To make more clear how a class is used try to:
//  use dash-case or kabab-case for style classes
//  use camelCase for JavaScript classes

@import 'base/sizes.scss';

@import 'base/colors.scss';
@import 'base/fonts.scss';
@import 'base/elements.scss';

@import 'components/alert.scss';
@import 'components/box.scss';
@import 'components/breadcrumbs.scss';
@import 'components/button.scss';
@import 'components/cards.scss';
@import 'components/dropzone.scss';
@import 'components/form.scss';
@import 'components/grid.scss';
@import 'components/linear-progress.scss';
@import 'components/pill.scss';

// global block modifiers: spacing, backgrounds, colors, etc
@import 'modifiers/backgrounds.scss';
@import 'modifiers/utilities.scss';

// section/page specific styles
@import 'views/CreateAccountPage.scss';
@import 'views/SetupWizardPage.scss';

// Branded client themes
@import 'themes/default.scss';
@import 'themes/nbcu.scss';

.savings { color: $vin-green; }
.loss { color: $vin-red; }
input.small { width: 46px; }
td.fuel-savings-label { width: 175px; }

.passwordVisibilityToggle {
  position: absolute;
}

// Class defined by Quicksight lib:
.quicksight-embedding-iframe {
  border-width: 0;
}

.temporary-dashboard-page-preview {
  width: 100%;
}

.cf-turnstile {
  height: 0;
  width: 0;
}
