// SetupWizardPage

.SetupWizard {

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: -1;

    background-color: rgb(63, 73, 81);
    background-image: none;
  }

  .step-count {
    font-size: 0.813rem;
    color: rgb(154, 160, 166);
  }

  // override MUI classes

  .MuiFormControlLabel-label {
    font-size: 0.75rem !important;
  }

}

